
import ClickOutside from 'vue-click-outside'
export default {
  name: 'ProcessSvg',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      activeBox: 0,
      show: false,
      x: 0,
      y: 0,
    }
  },
  computed: {
    tooltipText() {
      return this.$t(`vulnerability_tooltip_${this.activeBox}`)
    },
    translationExists() {
      return this.$i18n.locale === 'en' || this.$i18n.locale === 'lt'
    },
    getTranslation4() {
      return this.$i18n.locale === 'lt'
        ? this.$i18n.t('vulnerability.reports.process.item5').split(' ')[1]
        : this.$i18n.t('vulnerability.reports.process.item5').split(' ')[1] +
            ' ' +
            this.$i18n.t('vulnerability.reports.process.item5').split(' ')[2]
    },
  },
  methods: {
    toggleTooltip(event, index) {
      this.activeBox === index ? (this.show = !this.show) : (this.show = true)
      this.activeBox = index

      const bodyRect = document.body.getBoundingClientRect()
      const clickedQuestionRect = event.currentTarget.getBoundingClientRect()
      const offsetX = clickedQuestionRect.left - bodyRect.left
      const offsetY = clickedQuestionRect.top - bodyRect.top

      this.x = Math.round(offsetX + clickedQuestionRect.width / 2)
      this.y = Math.round(offsetY + clickedQuestionRect.height / 2)
    },
  },
}
